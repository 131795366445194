<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip v-if="!!tooltip" bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            depressed
            :text="text"
            :rounded="rounded"
            :icon="isIconBtn"
            :class="btnClass"
            :color="color"
            v-bind="attrs"
            v-on="{ ...menu, ...tooltip }"
          >
            <v-icon v-if="!!icon" :class="{ 'mr-2': !isIconBtn }">
              {{ icon }}
            </v-icon>
            <span>{{ label }}</span>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
      <v-btn
        v-else
        depressed
        :text="text"
        :rounded="rounded"
        :icon="isIconBtn"
        :class="btnClass"
        :color="color"
        v-bind="attrs"
        v-on="menu"
      >
        <v-icon v-if="!!icon" :class="{ 'mr-2': !isIconBtn }">
          {{ icon }}
        </v-icon>
        <span>{{ label }}</span>
      </v-btn>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>
export default {
  props: {
    rounded: {
      type: Boolean
    },
    text: {
      type: Boolean
    },
    btnClass: {
      type: String
    },
    color: {
      type: String
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    tooltip: {
      type: String
    }
  },

  computed: {
    isIconBtn() {
      return this.icon && !this.label;
    }
  }
};
</script>
